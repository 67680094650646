import "@/styles/index.scss";
import "./modules/Menu";
import "./modules/Header";
import "./modules/Video";
import "./modules/Tooltip";
import "./utils/scroll";
import "./form/index";

class App {
    _addEventListeners() {
        window.addEventListener("resize", this._onResize.bind(this));
        window.addEventListener("mousemove", this._onMouseMove.bind(this));
    }
}

new App();

console.log("%c Developed by ANTI - https://anti.as/", "background: #000; color: #fff;");
