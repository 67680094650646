import {
	formatValue,
	clearView,
	createCategoryButton,
	createCategoryDiv,
	hideUnselectedCategories,
	showSelectedCategory,
	createCategoryOptions,
	updateInitialButtonWithSelection,
	createCustomField,
	createCategoryBack,
	createOverlayTrigger,
	setActiveView,
	hideView,
	updateCounter,
	toggleSelected,
} from "./utils";

import {
	VIEW_2,
	VIEW_3,
	INITIAL_BUTTONS,
	NATIVE_SELECTS,
	SELECT_DATA,
	RESET_BUTTONS,
	COUNTER,
	SELECTED_ICON_TOP,
	SELECTED_ICON_LEFT,
	SELECTED_ICON_BOTTOM,
	SUBMITTED_ICON_TOP,
	SUBMITTED_ICON_LEFT,
	SUBMITTED_ICON_BOTTOM,
	SUBMITTED_LABEL_TOP,
	SUBMITTED_LABEL_LEFT,
	SUBMITTED_LABEL_BOTTOM,
	FORM_SUCCESS,
} from "./dom";

const TOP_LEVEL_ICONS = window.CATEGORY_ICONS;
const CUSTOM_SELECTS = [];
const CUSTOM_INPUT_FIELDS = [];
const SELECTED_CUSTOM_OPTIONS = new Set();
let selectIndex = 0;

const FORM_STATE = JSON.parse(window.localStorage.getItem("formState"));

let nativeOptionsState = [
	{
		selected: false,
		value: "",
		text: "",
		imageUrl: "",
	},
	{
		selected: false,
		value: "",
		text: "",
		imageUrl: "",
	},
	{
		selected: false,
		value: "",
		text: "",
		imageUrl: "",
	},
];

const setSelectedIcons = () => {
	[SELECTED_ICON_LEFT, SELECTED_ICON_TOP, SELECTED_ICON_BOTTOM].forEach((icon, index) => {
		if (icon === null) return;

		icon.src = FORM_STATE[index].imageUrl;
	});
};

const setSelectedOptions = () => {
	FORM_STATE.forEach((formGroup, index) => {
		const selectElement = NATIVE_SELECTS[index];
		if (!selectElement) {
			console.warn(`No select element found for form group at index ${index}.`);
			return;
		}

		const options = [...selectElement.querySelectorAll("option")];
		options.forEach((option) => (option.selected = false));

		const matchingOption = options.find((option) => formatValue(formGroup.value) === formatValue(option.value));

		if (matchingOption) {
			matchingOption.selected = true;
			console.log(`Matching value from formGroup at index ${index} applied to select element.`);

			if (formGroup.text) {
				updateInitialButtonWithSelection({
					buttons: INITIAL_BUTTONS,
					selectIndex: index,
					selectionText: formGroup.text,
					imageUrl: formGroup.imageUrl,
				});
			}
		}
	});
};

const setSubmittedIcons = () => {
	[SUBMITTED_ICON_LEFT, SUBMITTED_ICON_TOP, SUBMITTED_ICON_BOTTOM].forEach((icon, index) => {
		icon.textContent = nativeOptionsState[index].value.replace("ø", "o").replace("å", "a").replace("æ", "ae").replace(" ", "");
	});
};

const setSubmittedLabels = () => {
	[SUBMITTED_LABEL_LEFT, SUBMITTED_LABEL_TOP, SUBMITTED_LABEL_BOTTOM].forEach((text, index) => {
		text.textContent = nativeOptionsState[index].text.toLowerCase();
	});
};

if (FORM_STATE) {
	//Sync the localStorage object and the nativeOptionsState that we use in this file
	nativeOptionsState = FORM_STATE;

	if (FORM_SUCCESS) {
		setSubmittedIcons();
		setSubmittedLabels();
		console.log("submittedforms");
	}

	if (COUNTER) {
		updateCounter({ nativeOptionsState: nativeOptionsState, counter: COUNTER });
		setSelectedOptions();
	}

	if (SELECTED_ICON_TOP) {
		setSelectedIcons();
	}
}

const updateLocalStorageState = (state) => window.localStorage.setItem("formState", JSON.stringify(state));

const updateState = ({ selectIndex, selected, value = "", imageUrl = "", text = "" }) => {
	nativeOptionsState[selectIndex].selected = selected;
	nativeOptionsState[selectIndex].value = value;
	nativeOptionsState[selectIndex].imageUrl = imageUrl;
	nativeOptionsState[selectIndex].text = text;

	updateLocalStorageState(nativeOptionsState);
};

const resetSelection = (index) => {
	const nativeSelect = NATIVE_SELECTS[index].querySelector("select");
	toggleSelected({
		item: nativeSelect,
		selected: false,
	});
	nativeSelect.value = "";
	NATIVE_SELECTS[index].dispatchEvent(new Event("change"));
	updateCounter({ nativeOptionsState: nativeOptionsState, counter: COUNTER });

	updateInitialButtonWithSelection({
		buttons: INITIAL_BUTTONS,
		selectIndex: index,
		selectionText: `Forslag ${index + 1} +`,
		imageUrl: "",
	});

	updateMainIcons("", index, "");

	RESET_BUTTONS[index].style.display = "none";
	INITIAL_BUTTONS[index].classList.remove("filled");
};

RESET_BUTTONS.forEach((resetButton, index) => {
	const handleResetClick = (event) => {
		event.stopPropagation();
		event.preventDefault();

		updateState({
			selectIndex: index,
			selected: false,
			value: "",
			imageUrl: "",
			text: "",
		});
		resetSelection(index);
	};

	resetButton.addEventListener("click", handleResetClick);
});

INITIAL_BUTTONS.forEach((button, index) => {
	button.addEventListener("click", () => {
		selectIndex = index;
		clearView(VIEW_2);
		clearView(VIEW_3);
		createFormViews();
	});
});

const createFormViews = () => {
	createCategoryBack({
		level: 2,
		text: "Velg kategori",
		VIEW_TO_CLEAR: VIEW_2,
	});

	SELECT_DATA.forEach((selectCategory) => {
		const categoryLabel = selectCategory.selectId;
		const categoryButton = createCategoryButton(categoryLabel);
		const categoryDiv = createCategoryDiv(categoryLabel);
		const customInputField = createCustomField(categoryLabel);
		const overlayTrigger = createOverlayTrigger();

		CUSTOM_INPUT_FIELDS.push(customInputField);

		VIEW_2.appendChild(categoryButton);
		setActiveView(VIEW_2);

		categoryButton.addEventListener("click", () => {
			setActiveView(VIEW_3);

			createCategoryBack({
				level: 3,
				text: categoryLabel,
				VIEW_TO_CLEAR: VIEW_3,
			});

			VIEW_3.appendChild(categoryDiv);
			categoryDiv.appendChild(customInputField);
			hideUnselectedCategories(categoryLabel);
			showSelectedCategory(categoryLabel);
			onCustomSelectChange();
			onCustomInput();
		});

		const categoryOptions = selectCategory.children.map((categoryOption) =>
			createCategoryOptions({
				categoryOption: categoryOption,
				selectedCustomOption: SELECTED_CUSTOM_OPTIONS,
				selectIndex: selectIndex,
				customSelects: CUSTOM_SELECTS,
			})
		);

		categoryOptions.forEach((categoryOption) => categoryDiv.appendChild(categoryOption));

		categoryDiv.appendChild(overlayTrigger);
		overlayTrigger.addEventListener("click", () => {
			customInputField.classList.add("active");
		});
	});
};

const onCustomSelectChange = () => {
	const nativeOptions = [...NATIVE_SELECTS[selectIndex].querySelectorAll("option")];

	CUSTOM_SELECTS.forEach(({ optionButton, imageUrl }) => {
		optionButton.addEventListener("click", (event) => {
			const formattedCustomOption = formatValue(event.target.textContent.trim());

			nativeOptions.forEach((nativeOption) => {
				const formattedOption = formatValue(nativeOption.textContent.trim());

				if (formattedOption === formattedCustomOption) {
					toggleSelected({
						item: nativeOption,
						selected: true,
					});

					NATIVE_SELECTS[selectIndex].dispatchEvent(new Event("change"));
					SELECTED_CUSTOM_OPTIONS.add(formattedCustomOption);

					updateInitialButtonWithSelection({
						buttons: INITIAL_BUTTONS,
						selectIndex: selectIndex,
						selectionText: nativeOption.text,
						imageUrl: imageUrl,
					});

					updateMainIcons(imageUrl, selectIndex, nativeOption.text);
					updateState({
						selectIndex: selectIndex,
						selected: true,
						value: formattedOption,
						imageUrl: imageUrl,
						text: nativeOption.textContent,
					});
				}
			});

			hideView(VIEW_2);
			hideView(VIEW_3);
			updateCounter({ nativeOptionsState: nativeOptionsState, counter: COUNTER });
		});
	});
};

const onCustomInput = () => {
	const customFields = [...document.querySelectorAll(".custom-inputs > div")];

	CUSTOM_INPUT_FIELDS.forEach((inputField) => {
		const button = inputField.querySelector("button");
		const input = inputField.querySelector("input");
		const textarea = inputField.querySelector("textarea");

		button.addEventListener("click", (event) => {
			event.preventDefault();

			const category = button.getAttribute("data-category");
			const customSuggestion = input.value;
			const textAreaValue = textarea.value;

			const iconUrl = TOP_LEVEL_ICONS.filter((icon) => {
				return category === icon.title;
			});

			if (customSuggestion) {
				const imageUrl = iconUrl[0].allIcons[selectIndex];
				const customInput = customFields[selectIndex].querySelector("input");
				const customTextArea = customFields[selectIndex].querySelector("textarea");
				customInput.value = customSuggestion;
				customTextArea.value = textAreaValue;

				updateInitialButtonWithSelection({
					buttons: INITIAL_BUTTONS,
					selectIndex: selectIndex,
					selectionText: customSuggestion,
					imageUrl: imageUrl,
				});

				updateMainIcons(imageUrl, selectIndex, customSuggestion);
				hideView(VIEW_2);
				hideView(VIEW_3);

				updateState({
					selectIndex: selectIndex,
					selected: true,
					value: customSuggestion,
					text: customSuggestion,
					imageUrl: imageUrl,
				});

				updateCounter({ nativeOptionsState: nativeOptionsState, counter: COUNTER });
			}
		});
	});
};

const updateMainIcons = (imageUrl, selectIndex, altText = "") => {
	if (selectIndex === 0) {
		SELECTED_ICON_LEFT.src = imageUrl;
		SELECTED_ICON_LEFT.setAttribute("alt", altText);
	}

	if (selectIndex === 1) {
		SELECTED_ICON_TOP.src = imageUrl;
		SELECTED_ICON_TOP.setAttribute("alt", altText);
	}

	if (selectIndex === 2) {
		SELECTED_ICON_BOTTOM.src = imageUrl;
		SELECTED_ICON_BOTTOM.setAttribute("alt", altText);
	}
};

// Select all mark elements with the 'highlight' class on the page
const markElements = document.querySelectorAll("mark.text-icon");

// Loop through each mark element
markElements.forEach((markElement) => {
	// Get the text from the mark element
	const text = markElement.textContent;

	// Split the text into words
	const words = text.split(" ");

	// Check if there are any words
	if (words.length > 0) {
		// Wrap the first word with a span and the specified class
		words[0] = `<span class="kulturhavna-sans">${words[0]}</span>`;
	}

	// Join the words back into a string with spaces and update the mark element's HTML
	markElement.innerHTML = words.join(" ");
});
