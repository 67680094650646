document.addEventListener('DOMContentLoaded', function() {
  const icons = document.querySelectorAll('.form__icon');

  icons.forEach(icon => {
    const tooltip = icon.querySelector('.form__info');

    // Function to show and adjust tooltip
    function showTooltip() {
      if (!tooltip) return; // Prevent errors if tooltip is null
      tooltip.classList.add('active');

      const iconRect = icon.getBoundingClientRect();
      const tooltipRect = tooltip.getBoundingClientRect();

      if (iconRect.left + tooltipRect.width > window.innerWidth) {
        tooltip.style.right = '0px';
        tooltip.style.left = 'auto';
      } else if (iconRect.right - tooltipRect.width < 0) {
        tooltip.style.left = '0px';
        tooltip.style.right = 'auto';
      } else {
        tooltip.style.left = `${iconRect.width / 2 - tooltipRect.width / 2}px`;
        tooltip.style.right = 'auto';
      }

      tooltip.style.top = 'auto';
      tooltip.style.bottom = 'calc(100% + 16px)';
    }

    // Function to hide tooltip
    function hideTooltip() {
      if (!tooltip) return; // Prevent errors if tooltip is null
      tooltip.classList.remove('active');
    }

    icon.addEventListener('mouseenter', showTooltip);
    icon.addEventListener('mouseleave', hideTooltip);

    icon.addEventListener('touchstart', function(event) {
      event.preventDefault();
      if (!tooltip) return; // Prevent errors if tooltip is null
      const isVisible = tooltip.classList.contains('active');
      if (!isVisible) {
        showTooltip();
      } else {
        hideTooltip();
      }
    }, { passive: false });

    document.addEventListener('touchstart', function(event) {
      if (!icon.contains(event.target)) {
        hideTooltip();
      }
    });
  });
});
